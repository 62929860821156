<template>
    <div class="index">
        <z-header :curActive="3"></z-header>
        <div class="typeArea">
            <z-title :textEn="`PLANTING SYSTEM`" :text="`种植系统`" :color="`#867d70`"></z-title>
            <div class="planting">
                <div class="img">
                    <img src="../../imgs/craft/a1.png">
                </div>
                <div class="container">
                    <span>大数据辅助种植管理系统</span>
                    <i></i>
                    <p>构建勐海县普洱茶品种作物生长模型，对普洱茶种植的生长过程、生长环境、基因型与环境互作关系以及种植管理等多维度信息进行建模分析，形成种植指导意见，并全程记录种植档案、投入品使用、农事操作等生产过程信息，实现普洱茶种植的智能化、精准化、数字化辅助管理以及种植全过程的留痕、追溯及监测。</p>
                    <div>
                        <!-- <a href="http://106.60.12.199:12000/#/login" target="_blank"> -->
                        <a href="https://zz.mhtea.net/" target="_blank">
                            <img v-if="screenState" src="../../imgs/craft/c1.png">
                            <img v-else src="../../imgs/craft/c1app.png">
                        </a>
                        <!-- <a href="#">
                            <img v-if="screenState" src="../../imgs/craft/c2.png">
                            <img v-else src="../../imgs/craft/c2app.png">
                        </a> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- 品质溯源 -->
        <div class="quality">
            <img v-if="screenState" src="../../imgs/craft/d1.png">
            <img v-else src="../../imgs/craft/d1app.png">
            <div class="typeArea">
                <z-title :textEn="`QUALITY TRACEABILITY`" :text="`品质溯源`" :color="`#fff`"></z-title>
                <div class="content">
                    <p>以普洱茶为主要品种搭建全县统一的农产品质量安全追溯体系和追溯平台，建立健全普洱茶种植规范标准和葡萄酒生产流程规范及全程追溯体系，通过一个追溯二维码标签，综合展现产品名称、数量、收获时间、生产批次、质检情况、生产主体、联系电话等追溯基本信息要素，通过标准体系和追溯平台的建设，全面提升勐海县农产品质量安全的智慧监管能力。集成大数据辅助种植管理系统中生产管理、环境监测及第三方监管检测等相关数据。</p>
                    <div  v-if="screenState">
                        <div class="content-item" v-for="(item,index) of qualityData.slice(0,2)" :key="index">
                            <img :src="require(`../../imgs/craft/`+item.img)">
                            <div>
                                <span>{{item.name}}</span>
                                <p v-for="(i,ind) in item.textList" :key="ind">{{i}}</p>
                            </div>
                        </div>
                    </div>
                    <div   v-if="screenState" class="content-item con-item-last" >
                        <img :src="require(`../../imgs/craft/`+qualityData[2].img)">
                        <div>
                            <span>{{qualityData[2].name}}</span>
                            <p v-for="(item,index) in qualityData[2].textList" :key="index">{{item}}</p>
                        </div>
                    </div>
                    <div v-else>
                        <div ref="imgBox" class="imgBox">
                            <router-link to="/qualityInfo?id=0"><img src="../../imgs/craft/b1app.png" alt="查询平台"></router-link>
                            <router-link to="/qualityInfo?id=1"><img src="../../imgs/craft/b2app.png" alt="采集平台"></router-link>
                            <router-link to="/qualityInfo?id=2"><img src="../../imgs/craft/b3app.png" alt="数据服务平台"></router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 制茶技艺 -->
        <div class="typeArea">
            <z-title :textEn="`TEA MAKING TECHNIQUE`" :text="`制茶技艺`" :color="`#867d70`"></z-title>
            <div class="planting" v-for="(item,index) of teaData" :key="index">
                <div class="img">
                    <img :src="require(`../../imgs/craft/`+item.url)">
                </div>
                <div class="container app-container">
                    <span>{{item.name}}</span>
                    <i></i>
                    <p>{{item.text1}}</p>
                    <p>{{item.text2}}</p>
                </div>
            </div>
        </div>
	</div>
</template>
<script>
import zHeader from "../../components/header.vue"
import zTitle from "../../components/z-title.vue"
import craftData from "./data"
export default {
    components:{zHeader,zTitle},
    data(){
        return{
            screenState:true,
            qualityData:[],
            teaData:[]
        }
    },
    mounted(){
		
	},
    created(){
        let that = this
        // 初始化获取浏览器窗口大小
		let screenWidth = document.body.clientWidth;
		// 根据浏览器窗口大小判断是否显示页面内的部分内容
		if(screenWidth<1200){
			this.screenState=false;
		}else{
			this.screenState=true;
		}

        this.qualityData = craftData.qualityData;
        this.teaData = craftData.teaData;

        if(this.$route.query.id){
            if(this.$route.query.id == 1){
                if(this.screenState){
                    document.body.scrollTop = 800;
                    document.documentElement.scrollTop = 800;
                }else{
                    document.body.scrollTop = 285;
                    document.documentElement.scrollTop = 285;
                }
            }else if(this.$route.query.id == 2){
                if(this.screenState){
                    document.body.scrollTop = 1230;
                    document.documentElement.scrollTop = 1230;
                }else{
                    document.body.scrollTop = 1000;
                    document.documentElement.scrollTop = 1000;
                }
            }else{
                if(this.screenState){
                    document.body.scrollTop = 2230;
                    document.documentElement.scrollTop = 2230;
                }else{
                    document.body.scrollTop = 1630;
                    document.documentElement.scrollTop = 1630;
                }
            }
        }
    }
}
</script>
<style scoped>
    .index{
        padding-bottom:150px;
    }
    .index .typeArea{
        display: flex;
        flex-direction: column;
    }
    /* 种植系统 */
    .planting{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
    }
    .planting > .img{
        width: 541px;
        height: 340px;
        border-radius: 4px;
    }
    .planting > .img >img{
        width: 100%;
        height: 100%;
    }
    .planting > .container{
        width: 50%;
    }
    .planting > .container > span{
        font-size: 24px;
        font-weight: bold;
        color: #867d70;
    }
    .planting > .container > i{
        display: block;
        width: 50px;
        border-top: 2px solid #705a53;
        margin-top: 24px;
    }
    .planting > .container > p{
        font-size: 15px;
        color: #666666;
        line-height: 28px;
        margin-top: 34px;
    }
    .planting > .container > p:last-child{
        margin-top: 16px;
    }
    .planting > .container img{
        margin: 40px 30px 0 0;
        cursor: pointer;
    }
    .quality{
        width: 100%;
        height: 750px;
        position: relative;
        margin-top: 100px;
    }
    .quality > img{
        width: 100%;
        height: 100%;
        position: absolute;
    }
    .quality > .typeArea{
        position: absolute;
        left: 50%;
        transform: translate(-50%);
    }
    .quality > .typeArea > .content{
        height: 535px;
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: #fff;
        z-index: 9;
        padding: 40px 40px 0;
        box-sizing: border-box;
    }
    .quality > .typeArea > .content > p{
        font-size: 15px;
        color: #666;
        line-height: 28px;
        padding-bottom: 20px;
        margin-bottom: 30px;
        border-bottom: 1px solid #ddd;
    }
    .quality > .typeArea > .content > div{
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .content-item{
        display: flex;
        flex-direction: row;
        width: 49%;
        cursor: pointer;
    }
    .content-item:first-child{
        width: 47%;
    }
    .content-item>img{
        width: 125px;
        height: 125px;
        border-radius: 4px;
    }
    .content-item >div{
        margin-left: 20px;
    }
    .content-item >div>span{
        font-size: 18px;
        color: #333;
        font-weight: 500;
        margin-bottom: 12px;
        display: block;
    }
    .content-item >div>p{
        font-size: 14px;
        color: #999;
        line-height: 28px;
    }
    .quality > .typeArea  .content .con-item-last{
        justify-content: flex-start;
    }

    /* 移动端样式 */
    @media only screen and (max-width:1199px) {
        .index .typeArea{
            width: 100%;
        }
        .planting{
            flex-direction: column;
            margin-top: 5px;
        }
        .planting > .img{
            width: 100%;
            height: auto;
            padding: 0 20px;
            box-sizing: border-box;
        }
        .planting > .container{
            width: 100%;
            padding: 0 20px;
            box-sizing: border-box;
        }
        .planting > .container > span{
            width: 100%;
            font-size:16px;
            text-align: center;
            display:block;
            margin:30px 0 25px 0;
        }
        .planting > .container > i{
            display: none;
        }
        .planting > .container > p{
            font-size:14px;
            line-height: 28px;
            display:hidden;
            margin-top: 25px;
            color:#333;
        }
        .planting > .container>div{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }
        .planting > .container>div>a{
            width:150px;
            height:75px;
            border-radius:5px;
            overflow: hidden;
            margin-top: 18px;
        }
        .planting > .container>div>a>img{
            width:100%;
            margin:0;
        }
        .planting > .img >img{
            display:block;
        }
        .quality{
            margin-top:40px;
            height:630px;
        }
        .quality > .typeArea > .content{
            background-color:transparent;
            box-sizing:border-box;
            padding:0;
        }
        .quality > .typeArea > .content>p{
            color:#fff;
            font-size:14px;
            padding: 2px 20px 0;
            line-height:28px;
            border: none;
        }
        .quality > .typeArea > .content>div{
            width:100%;
            display:flex;
            overflow: auto;
            justify-content: center;
        }
        .quality > .typeArea > .content>div>div::-webkit-scrollbar{
            display: none;
        }
        .quality > .typeArea > .content> div > div{
            overflow: auto;
            flex:1;
            display:flex;

        }
        .quality > .typeArea > .content> div > div  img{
            width:150px;
            border-radius:5px;
            margin:0 20px;
        }
        .typeArea .planting .app-container{
            padding:0;
            margin: 0 20px 25px;
            background-color: #f8f8f8;
            width: calc(100vw - 40px);
            padding-bottom:25px;
            border-radius:0 0 5px 5px;
        }
        .typeArea .planting .app-container > span{
            text-align: left;
            margin: 20px 0 15px 15px;
            color:#bfa377;
        }
        .typeArea .planting .app-container > i{
            border-top:2px solid #bfa377;
            width: 80px;
            display:block;
            margin-left:15px;
        }
        .typeArea .planting .app-container > p{
            font-size:14px;
            line-height: 25px;
            padding:0 15px;
            color:#333;
        }
        .index{
            padding: 0;
        }
        .imgBox{
            padding:0 90px
        }
    }
</style>